import ContractBase, { TransactionPromise } from "./ContractBase";

export default class SellContract extends ContractBase {

  public setForSaleAsync = (sellId: number, ownerAddress: string, tokenId: number | string, unitPrice: number, saleQuantity: number, nftCreatorContractAddress: string, isPrivateSell: boolean, privateSellAddress: string = "0x0000000000000000000000000000000000000000"): TransactionPromise => {
    var txPromise = this.contract.methods.setForSale(sellId, ownerAddress, tokenId, unitPrice, saleQuantity, nftCreatorContractAddress, isPrivateSell, privateSellAddress).send();

    return ContractBase.defineTxIdMethod(txPromise);
  }

  public setSaleStatusAsync = (sellId: number, isSale: boolean): TransactionPromise => {
    var txPromise = this.contract.methods.setSaleStatus(sellId, isSale).send();

    return ContractBase.defineTxIdMethod(txPromise);
  }

  public updateUnitPriceAsync = (sellId: number, unitPrice: number): TransactionPromise => {
    var txPromise = this.contract.methods.updateUnitPrice(sellId, unitPrice).send()

    return ContractBase.defineTxIdMethod(txPromise);
  }

  public withdrawAsync = async (sellId: number, amount: number, to: string, price: number): Promise<TransactionPromise> => {
    var txPromise = await this.contract.methods.withdraw(sellId, amount, to).send({ value: price })
      .on('receipt', function (receipt: any) {
        console.log("🚀 ~ file: SELLContract.tsx ~ line 26 ~ SellContract ~ .on ~ receipt", receipt)
        return receipt;
      })
      .on('error', function (error: any, receipt: any) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        console.log("🚀 ~ file: SELLContract.tsx ~ line 29 ~ SellContract ~ .on ~ receipt", receipt)
        console.log("🚀 ~ file: SELLContract.tsx ~ line 29 ~ SellContract ~ .on ~ error", error)
        return receipt;
      })
    return ContractBase.defineTxIdMethod(txPromise);
  }

  public callOwnerAddressAsync = (): Promise<string> => {
    return this.contract.methods.ownerAddress().call();
  }

  public callFeeRateAsync = (): Promise<number> => {
    return this.contract.methods.feeRate().call();
  }

  public callSalesAsync = (sellId: number): Promise<Array<any>> => {
    return this.contract.methods.sales(sellId).call();
  }

}