import _ from 'lodash';
import { ErrorCode } from '../constants'
import { getLocalizedText } from '../localizationManager';
import { SUPPORTED_NETWORKS } from './utilities';

export const SupportedNetworkMessage = `Please use one of the following: ${_.uniq(SUPPORTED_NETWORKS.map(chainData => chainData.name))}`;

export const ErrorMessages: { [P in ErrorCode]: string } = {
    [ErrorCode.CONNECTED_ACCOUNT_NOT_EXISTS]: getLocalizedText(`There are no connected accounts !`),
    [ErrorCode.UNSUPPORTED_CHAIN]: getLocalizedText(`Unsupported chain !\n${SupportedNetworkMessage}`),
    [ErrorCode.UNSUPPORTED_NETWORK]: getLocalizedText(`Unsupported network !\n${SupportedNetworkMessage}`),
}