
import { makeUserManager } from 'react-oidc';
import Constants from './constants'

var settings = {
    // URL of your OpenID Connect server.
    // The library uses it to access the metadata document
    authority: Constants.IdentityURL,

    client_id: 'js',
    redirect_uri: `${window.location.origin}/callback`,
    //silent_redirect_uri:`${window.location.origin}/callback`,
    post_logout_redirect_uri: `${window.location.origin}/logout`,

    // What you expect back from The IdP.
    // In that case, like for all JS-based applications, an identity token
    // and an access token
    response_type: 'code',

    // Scopes requested during the authorisation request
    scope: 'openid profile genesisAPI',

    // Number of seconds before the token expires to trigger
    // the `tokenExpiring` event
    accessTokenExpiringNotificationTime: 30,

    // Do we want to renew the access token automatically when it's
    // about to expire?
    automaticSilentRenew: true,

    // Do we want to filter OIDC protocal-specific claims from the response?
    filterProtocolClaims: true,
    //Get user data as response too
    loadUserInfo: true,
    //prompt: 'login'
};

// `UserManager` is the main class exposed by the library
const userManager = makeUserManager(settings)

// You can hook a logger to the library.
// Conveniently, the methods exposed by the logger match
// the `console` object
//Oidc.Log.logger = console;

// When a user logs in successfully or a token is renewed, the `userLoaded`
// event is fired. the `addUserLoaded` method allows to register a callback to
// that event
/* userManager.events.addUserLoaded(function (loadedUser) {
  userManager.storeUser(loadedUser);
  console.warn('addUserLoaded', loadedUser);
}); */

// Same mechanism for when the automatic renewal of a token fails
userManager.events.addSilentRenewError(function (error) {
    console.error('addSilentRenewError', error);
});

// When the automatic session management feature detects a change in
// the user session state, the `userSignedOut` e"vent is fired.
userManager.events.addUserSignedOut(function () {
    console.warn('addUserSignedOut');
});

function getToken() {
    for (let i = 0; i < sessionStorage.length; i++) {
        if (sessionStorage.key(i).startsWith('oidc.user:')) {
            var store = sessionStorage.getItem(sessionStorage.key(i));
            store = JSON.parse(store);
            return store['access_token'];
        }
    }
    return null;
}

function isLoggedIn() {
    var tokenExists = false;
    var claimsExists = false;
    var profileExists = false;

    for (let i = 0; i < sessionStorage.length; i++) {
        if (sessionStorage.key(i).startsWith('oidc.user:')) {
            tokenExists = true;
            break;
        }
    }

    for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i) === 'authorizationClaims') claimsExists = true;
        if (localStorage.key(i) === 'profile') profileExists = true;
    }

    return tokenExists && claimsExists && profileExists;
}

export { userManager, settings, isLoggedIn, getToken };