import ContractBase, { TransactionPromise } from "./ContractBase";

export default class NewERC1155Contract extends ContractBase {
    public callBalanceOfAsync = (address: string, tokenId: number | string): Promise<number> => {
        return this.contract.methods.balanceOf(address, tokenId).call();
    }

    public callIsApprovedForAllAsync = (address: string, operator: string): Promise<boolean> => {
        return this.contract.methods.isApprovedForAll(address, operator).call();
    }

    public sendSetApprovalForAllAsync = (operator: string, approved: boolean): TransactionPromise => {
        var txPromise = this.contract.methods.setApprovalForAll(operator, approved).send();

        return ContractBase.defineTxIdMethod(txPromise);
    }

    public uri = (tokenId: number | string): Promise<string> => {
        return this.contract.methods.uri(tokenId).call();
    }

    public sendMintAsync = (to: string, id: number | string, amount: number, royaltyRate: number, tokenFullUri: string, data: string[] = []): TransactionPromise => {
        var txPromise = this.contract.methods.mint(to, id, amount, royaltyRate, tokenFullUri, data).send();

        return ContractBase.defineTxIdMethod(txPromise);
    }

    public sendMintBatchAsync = (to: string, ids: number[] | string[], amounts: number[], royaltyRates: number[], tokenFullUris: string[], /* r: number[], s: number[], v: number, */ data: string[] = []): TransactionPromise => {
        var txPromise = this.contract.methods.mintBatch(to, ids, amounts, royaltyRates, tokenFullUris,/*  r, s, v, */ data).send();

        return ContractBase.defineTxIdMethod(txPromise);
    }

    public safeTransferFrom = (from: string, to: string, tokenId: number | string, amount: number, data: string[] = []): TransactionPromise => {
        var txPromise = this.contract.methods.safeTransferFrom(from, to, tokenId, amount, data).send();

        return ContractBase.defineTxIdMethod(txPromise);
    }

    public callGetRoyaltyAddressAsync = (tokenId: number | string): Promise<string> => {
        return this.contract.methods.getRoyaltyAddress(tokenId).call();
    }

    public callGetRoyaltyRateAsync = (tokenId: number | string): Promise<number> => {
        return this.contract.methods.getRoyaltyRate(tokenId).call();
    }

    public _beforeTokenTransfer = (operator: string, from: string, to: string, tokenId: number | string, amount: number, data: string[] = []): TransactionPromise => {
        var txPromise = this.contract.methods._beforeTokenTransfer(operator, from, to, [tokenId], [amount], data).send();

        return ContractBase.defineTxIdMethod(txPromise);
    }
}