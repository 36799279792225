import { TransactionReceipt, PromiEvent } from "web3-core";
import { Contract } from "web3-eth-contract";

export interface TransactionPromise extends PromiEvent<TransactionReceipt> {
  getTxId: (cb?: Function) => Promise<string>;
}

export default abstract class ContractBase {
  public contract: Contract;

  constructor(contract: Contract) {
    this.contract = contract;
  }

  protected static defineTxIdMethod = (txPromise: TransactionPromise): TransactionPromise => {
    txPromise.getTxId = (cb) => ContractBase.getTxId(txPromise, cb);
    return txPromise;
  }

  protected static getTxId = (transaction: TransactionPromise, cb?: Function): Promise<string> => {
    return new Promise((resolve, reject) => {
      try {
        transaction
          .once('transactionHash', (hash) => {
            resolve(hash);
            if (!!cb)
              cb(hash);
          })
          .once('error', (error) => reject(error))
      } catch (error) {
        reject(error);
      }
    });
  }
}