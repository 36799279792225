import React from "react";
import { SupportedNetworkMessage } from "../common/helpers/common";

export default function NetworkErrorModal() {
    return <div style={{ textAlign: 'center' }}>
        <br />
       {/*  <Loader
            type="Triangle"
            color="#04d39f"
            height="60"
        /> */}
        <br />
        <h2>Wrong network</h2>
        <p>{SupportedNetworkMessage}</p>
    </div>
}
