import React from 'react';
import axios from 'axios';
import moment from './moment';
import Constants from './constants'
import _ from 'lodash';
import { getToken, userManager } from './authentication';
import { getLocalizedText, getCurrentLocale, getPreferredLanguage } from './localizationManager';
import { cacheGet, cacheSet, cacheLock, cacheUnlock } from './cacheManager';
import {
    Button,
    Card,
    CardBody,
    UncontrolledCollapse,
} from 'reactstrap';
import Toast from '../components/Toast';
import LocalizeText from '../components/LocalizeText';

const CACHE_REFRESH_INTERVAL_MIN = 5;

async function getResult(requestConfig, optConfig, isCacheable) {
    return new Promise(async function (resolve, reject) {
        try {
            var url = requestConfig.url.toLowerCase().replace(/^\/+|\/+$/g, '');
            var param = requestConfig.data;
            isCacheable = isCacheable || url.includes('parameter/');
            var preferredLang = getCurrentLocale();
            var sessionKey = isCacheable ? url + '/' + preferredLang + '/' + JSON.stringify(param) : '';
            var cachedData = isCacheable ? JSON.parse(sessionStorage.getItem(sessionKey)) : {};

            if (isCacheable && cachedData && moment() < moment(cachedData.expirationDate)) {
                if (JSON.stringify(param) === JSON.stringify(cachedData.params)) {
                    console.log(sessionKey + ' sonuçları cacheden alındı. Data: ', cachedData);
                    resolve(cachedData.content);
                }
            }
            else {
                if (isCacheable && cachedData)
                    sessionStorage.removeItem(sessionKey);

                var response = await Http.request(requestConfig);

                let arr = response.data.data.list || response.data.data;

                const reg = new RegExp(/@{(\w+(\.\w+)*)}/gm);

                arr = arr.map((item) => {
                    let valueKey = optConfig.getValue(item).toString();
                    let labelKey = optConfig.getLabel(item).toString();

                    var valueMatches = Array.from(valueKey.matchAll(reg));
                    var labelMatches = Array.from(labelKey.matchAll(reg));

                    for (const valueMatch of valueMatches) {
                        let val = _.get(item, valueMatch[1], "");
                        valueKey = valueKey.replace(valueMatch[0], val);
                    }

                    for (const labelMatch of labelMatches) {
                        let val = _.get(item, labelMatch[1], "");
                        labelKey = labelKey.replace(labelMatch[0], val);
                    }

                    return { label: labelKey, value: valueKey };
                });

                if (isCacheable) {
                    sessionStorage.setItem(sessionKey, JSON.stringify({
                        params: param,
                        content: arr,
                        expirationDate: moment().add(CACHE_REFRESH_INTERVAL_MIN, 'm'),
                    }));
                }
                resolve(arr);
                // })
                // .catch((e) => {
                //     console.error(e);
                // });
            }
            reject()
        } catch (error) {
            console.warn(error)
            reject(error)
        }
    }
    );
}

async function getTypeParameters(typeAgns) {
    var storageName = "session";
    try {
        var str = "typeParameters" + JSON.stringify(typeAgns);
        if (await cacheLock("getTypeParameters", storageName)) {
            var cacheObj = cacheGet(str, storageName);
            if (cacheObj != null)
                return cacheObj;
            else {
                var response = await Http.post('serviceDefinition/getTypeParameters', typeAgns);
                cacheSet(str, response.data, Constants.DEFAULT_CACHE_EXPIRATION_MIN, storageName);
                return response.data;
            }
        }
    } catch (e) {
        console.error(e);
    }
    finally {
        await cacheUnlock("getTypeParameters", storageName)
    }
    return {};
}

export function getDifferences(curObj, newObj) {
    function difference(curObj, newObj) {
        function changes(curObj, newObj) {
            return _.transform(curObj, function (result, value, key) {
                if (!_.isEqual(value, _.get(newObj, key))) {
                    if (_.isArray(value) && _.isArray(_.get(newObj, key)))
                        result[key] = _.differenceWith(value, _.get(newObj, key), _.isEqual);
                    else
                        result[key] = (_.isObject(value) && _.isObject(_.get(newObj, key))) ? changes(value, _.get(newObj, key)) : value;
                }
            });
        }
        return changes(curObj, newObj);
    }

    return sortByKeys(difference(curObj, newObj));
}

export function sortByKeys(obj) {
    return _(obj).toPairs().sortBy(0).fromPairs().value();
}

export function mergeArrayCustomizer(objValue, srcValue) {
    if (_.isArray(objValue))
        return srcValue;
}

export function ignoreFalsyData(datas) {
    var tempData = [...datas]

    for (var i = 0; i < tempData.length; i++) {
        var dataJson = tempData[i];
        for (var data in dataJson) {
            if (tempData[i][data] == null
                || tempData[i][data] === ''
                /* || tempData[i][data] === 0 */)
                tempData[i][data] = undefined;
        }
    }

    return tempData;
}

export function manipulateExcelReturnData(data) {
    return data.map(x => (
        {
            ...x.data,
            [Constants.ERROR_MESSAGE_COLUMN_NAME]: x.message + '\n\n' + x.errors.map(error => error.message).join('\n\n')
        }
    ))
}

export function getPreviewByFileName(fileName, asUrl = false, isOnlyBase64 = false, hasData = false) {
    var preview;

    if (isOnlyBase64 && hasData) {
        preview = require('../assets/img/file-icons/raw.svg');
    }
    else {
        if (fileName && fileName != "") {
            fileName = fileName.toLowerCase();
            var exInd = fileName.lastIndexOf(".");
            var fileExtension = fileName.substring(exInd + 1);

            if (fileExtension == 'xls' || fileExtension == 'xlsx')
                preview = require('../assets/img/file-icons/xls.svg');
            else if (fileExtension == 'pdf' || fileExtension == 'pdfx')
                preview = require('../assets/img/file-icons/pdf.svg');
            else if (fileExtension == 'docx' || fileExtension == 'docxx')
                preview = require('../assets/img/file-icons/doc.svg');
            else if (fileExtension == 'ppt' || fileExtension == 'pptx')
                preview = require('../assets/img/file-icons/ppt.svg');
            else if (fileExtension == 'tiff' || fileExtension == 'tif')
                preview = require('../assets/img/file-icons/tif.svg');
            else if (fileExtension == 'zip' || fileExtension == 'rar')
                preview = require('../assets/img/file-icons/zip.svg');

            if (preview == null)
                try {
                    preview = require(`../assets/img/file-icons/${fileExtension}.svg`);
                } catch { }
        }
    }

    if (preview == null)
        preview = require('../assets/img/no-image.png');

    if (asUrl)
        return `url(${preview})`;
    else
        return preview;
}

export function calculateBusinessDays(firstDate, secondDate) {
    var day1 = moment(firstDate);
    var day2 = moment(secondDate);
    var adjust = 0;

    if ((day1.dayOfYear() === day2.dayOfYear()) && (day1.year() === day2.year())) {
        return 0;
    }

    //Check if first date starts on weekends
    if (day1.day() === 6) { //Saturday
        //Move date to next week monday
        day1.day(8);
    } else if (day1.day() === 0) { //Sunday
        //Move date to current week monday
        day1.day(1);
    }

    if (day2.day() === 0) { //Sunday
        //Move date to previous week friday
        day2.day(-1);
    }

    var day1Week = day1.week();
    var day2Week = day2.week();

    //Check if two dates are in different week of the year
    if (day1Week !== day2Week) {
        //Check if second date's year is different from first date's year
        if (day2Week < day1Week) {
            day2Week += day1Week;
        }
        //Calculate adjust value to be substracted from difference between two dates
        adjust = -2 * (day2Week - day1Week);
    }

    return day2.diff(day1, 'days') + adjust;
}

// separatorType one of "group" or "decimal"
export function getNumberSeparator(locale, separatorType) {
    const sampleValue = 1000.1;
    return Intl.NumberFormat(locale)
        .formatToParts(sampleValue)
        .find(part => part.type === separatorType)
        .value;
}

export function jsonReplacer(key, value) {
    if (typeof value === 'function') {
        return value.toString();
    }
    else if (value instanceof RegExp) {
        return {
            __type__: 'RegExp',
            source: value.source,
            flags: value.flags,
        };
    }
    return value;
}

export function jsonReviver(key, value) {
    if (typeof value === 'string'
        && value.indexOf('function ') === 0) {
        let functionTemplate = `(${value})`;
        return eval(functionTemplate);
    }
    else if (value && value.__type__ === 'RegExp') {
        return new RegExp(value.source, value.flags);
    }
    return value;
}

export function handleRequestError(error, defaultMessage, callbackEvent) {
    if (error.code === 'ECONNABORTED')
        Toast.info(Notifications.Timeout);
    else if (error.response != null && error.response.status === 401)
        Toast.info(Notifications.TokenExpired);
    else if (error.response != null && error.response.status === 403)
        Toast.error(Notifications.AccessDenied);
    else if (error == 'WORKFLOW_DELEGATED')
        Toast.info(Notifications.WorkflowDelegated);
    else if (error == 'WORKFLOW_FAULTED')
        Toast.error(Notifications.WorkflowFaulted);
    else if (error == 'WORKFLOW_FINISHED')
        Toast.error(Notifications.WorkflowFinished);
    else if (defaultMessage)
        Toast.error(<>
            <span>{defaultMessage} </span>
            {callbackEvent ?
                <Button color="link" style={{ color: '#FFFFFF' }} className="alert-link"
                    onClick={() => callbackEvent(error, defaultMessage)}>
                    <LocalizeText keyCode="DETAIL_LABEL" />
                </Button>
                : null
            }
        </>);
    else if (error == 'UNSUCCESSFUL_RESPONSE')
        Toast.error(Notifications.ProcessFailed);
}

export function renderErrorDetails(error, defaultMessage) {
    var errorMessage1 = _.get(error, 'response.data.data.message') || _.get(error, 'message') || defaultMessage || "";
    var errorMessage2 = _.get(error, 'response.data.message') || errorMessage1 || "";

    var innerException = _.last(_.get(error, 'response.data.errors'));
    var innerExceptionMessage = _.get(innerException, 'message');
    var innerExceptionMessageText = _.get(innerException, 'messageText');
    var stackTrace = _.get(innerException, 'stackTrace') || error.stack;
    var url = _.get(error, 'config.url');
    var responseStatus = _.get(error, 'response.status');
    var responseStatusText = _.get(error, 'response.statusText');

    return <>
        <h4><p style={{ whiteSpace: 'pre-line' }}>{errorMessage1}</p></h4>
        <br />
        {url ?
            <>
                <h5>{getLocalizedText("ERROR_DETAILS_REQUEST_URL")}:</h5>
                {url}
                <br />
                <br />
            </>
            : null
        }
        {
            responseStatus && responseStatusText ?
                <>
                    <h5>{getLocalizedText("ERROR_DETAILS_RESPONSE_STATUS")}: </h5>{`${responseStatus} (${responseStatusText})`}
                    <br />
                    <br />
                </>
                : null
        }
        <h5>{getLocalizedText("ERROR_DETAILS_MESSAGE")}</h5>
        <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }}>{errorMessage2}</p>
        {innerException ?
            <>
                <br />
                <Button color="link" className="alert-link p-0" href="#" id="togglerBtn2">
                    {'Inner Exception'}
                </Button>
                <UncontrolledCollapse toggler="#togglerBtn2" defaultOpen={true}>
                    <Card>
                        <CardBody>
                            <h5>{getLocalizedText("ERROR_DETAILS_EXCEPTION")}</h5>
                            <br />
                            {innerExceptionMessage ?
                                <p>- {innerExceptionMessage}</p>
                                : null}
                            {innerExceptionMessageText ?
                                <p>- {innerExceptionMessageText}</p>
                                : null}
                            {stackTrace
                                ? <>
                                    <br />
                                    <Button color="link" className="alert-link p-0" href="#" id="togglerBtn" >{getLocalizedText("ERROR_DETAILS_STACKTRACE")}</Button>
                                    <UncontrolledCollapse toggler="#togglerBtn">
                                        <Card>
                                            <CardBody className="p-1" style={{ overflow: 'scroll' }}>
                                                <pre style={{ overflow: 'unset' }}>{stackTrace}</pre>
                                            </CardBody>
                                        </Card>
                                    </UncontrolledCollapse>
                                </>
                                : null}
                            <br />
                            <Button color="link" className="alert-link p-0" href="#" id="togglerBtn3">
                                {getLocalizedText('DETAIL_LABEL')}
                            </Button>
                            <UncontrolledCollapse toggler="#togglerBtn3">
                                <Card>
                                    <CardBody className="p-1" style={{ overflow: 'scroll' }}>
                                        <pre style={{ overflow: 'unset' }}>{JSON.stringify(innerException, null, 2)}</pre>
                                    </CardBody>
                                </Card>
                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </>
            : null}
    </>;
}

var Notifications = {
    BulkImportSuccess: getLocalizedText("BATCH_UPLOAD_STARTED"),
    BulkImportFailure: getLocalizedText("BATCH_UPLOAD_ERROR"),
    BulkImportNoRowsFound: getLocalizedText("IMPORT_NO_ROWS_FOUND"),
    FileExceedsSizeLimit: (limit) => getLocalizedText("FILE_EXCEEDS_SIZE_LIMIT", `${limit} KB`),
    UploadNotSupportedType: getLocalizedText("UNSUPPORTED_FILE_TYPE"),
    UploadAlreadyExists: getLocalizedText("FILE_ALREADY_UPLOADED"),
    ValidationFailure: getLocalizedText("FILL_IN_ALL_REQUIRED_FIELDS"),
    GetFailure: getLocalizedText("RETRIEVAL_ERROR"),
    ListingFailure: getLocalizedText("LISTING_ERROR"),
    ListingSuccess: getLocalizedText("LISTING_SUCCESSFUL"),
    DeleteSuccess: getLocalizedText("DELETED_SUCCESSFULLY"),
    DeleteFailure: getLocalizedText("DELETE_ERROR"),
    InsertSuccess: getLocalizedText("INSERTED_SUCCESSFULLY"),
    InsertFailure: getLocalizedText("INSERT_ERROR"),
    UpdateSuccess: getLocalizedText("UPDATED_SUCCESSFULLY"),
    UpdateFailure: getLocalizedText("UPDATE_ERROR"),
    VisualValidationFailure: getLocalizedText('VISUAL_VALIDATION_FAILURE'),
    DocumentRemove: getLocalizedText("DELETED_SUCCESSFULLY"),
    DocumentRemoveFailure: getLocalizedText("FILE_DELETE_ERROR"),
    DocumentValidationFailure: getLocalizedText("DIRECTORY_MUST_BE_SELECTED"),
    DownloadSuccess: getLocalizedText("DOWNLOAD_SUCCESSFUL"),
    DownloadFailure: getLocalizedText("DOWNLOAD_ERROR"),
    LoginFailure: getLocalizedText("LOGIN_FAILURE"),
    TokenExpired: getLocalizedText("SESSION_EXPIRED"),
    AccessDenied: getLocalizedText("NO_ACCESS_RIGHT"),
    Timeout: getLocalizedText("TIMEOUT"),
    ProcessSuccessful: getLocalizedText("PROCESS_SUCCESSFUL"),
    ProcessFailed: getLocalizedText("PROCESS_FAILED"),
    ResetPasswordEmailSent: getLocalizedText("RESET_PASSWORD_EMAIL_SENT"),
    FilterValidationFailure: getLocalizedText("FILL_IN_ALL_REQUIRED_FIELDS_IN_THE_FILTER"),
    WorkflowDelegated: getLocalizedText("WF_DELEGATED"),
    WorkflowFaulted: getLocalizedText("WF_FAULTED"),
    WorkflowFinished: getLocalizedText("WF_FINISHED"),
}

var ModalContent = {
    Add: {
        title: getLocalizedText("INSERT_CONFIRMATION_TITLE"),
        body: getLocalizedText("INSERT_CONFIRMATION"),
        posButtonText: getLocalizedText("SAVE_RECORD"),
        negButtonText: getLocalizedText("CANCEL"),
        modalStyle: 'bg-blue',
        posButtonColor: "warning",
    },
    Update: {
        title: getLocalizedText("UPDATE_CONFIRMATION_TITLE"),
        body: getLocalizedText("UPDATE_CONFIRMATION"),
        posButtonText: getLocalizedText("SAVE_RECORD"),
        negButtonText: getLocalizedText("CANCEL"),
        modalStyle: 'bg-blue',
        posButtonColor: "warning",
    },
    Remove: {
        title: getLocalizedText("DELETING_RECORD_TITLE"),
        body: getLocalizedText("DELETE_CONFIRMATION"),
        posButtonText: getLocalizedText("DELETE"),
        negButtonText: getLocalizedText("CANCEL"),
        modalStyle: 'bg-red',
        posButtonColor: "danger",
    },
    VisualRemove: {
        title: getLocalizedText("VISUAL_DELETE_CONFIRMATION_TITLE"),
        body: getLocalizedText("VISUAL_DELETE_CONFIRMATION"),
        posButtonText: getLocalizedText("DELETE"),
        negButtonText: getLocalizedText("CANCEL"),
        modalStyle: 'bg-red',
        posButtonColor: "warning",
    },
    DocumentRemove: {
        title: getLocalizedText("FILE_DELETE_CONFIRMATION_TITLE"),
        body: getLocalizedText("FILE_DELETE_CONFIRMATION"),
        posButtonText: getLocalizedText("DELETE"),
        negButtonText: getLocalizedText("CANCEL"),
        modalStyle: 'bg-red',
    },
    LogOut: {
        title: getLocalizedText("LOGOUT"),
        body: getLocalizedText("LOGOUT_CONFIRMATION"),
        posButtonText: 'Evet',
        negButtonText: 'Hayır',
        modalStyle: 'bg-red',
    },
    Empty: {
        title: getLocalizedText("ERROR_DETAILS_TITLE"),
        body: '',
        posButtonText: getLocalizedText("NOTIFICATION_OK_BUTTON"),
        modalStyle: 'bg-red',
    },
    Ok: {
        title: getLocalizedText("TITLE_DETAIL"),
        posButtonText: getLocalizedText("NOTIFICATION_OK_BUTTON"),
        modalStyle: 'bg-warning',
        posButtonColor: "warning",
    },
    Duplicate: {
        title: getLocalizedText("DUPLICATE_CONFIRMATION_TITLE"),
        body: getLocalizedText("DUPLICATE_CONFIRMATION"),
        posButtonText: getLocalizedText("DUPLICATE"),
        negButtonText: getLocalizedText("CANCEL"),
        modalStyle: 'bg-blue',
        posButtonColor: "primary",
    },
    ChangePassword: {
        title: getLocalizedText("CHANGE_PASSWORD_CONFIRMATION_TITLE"),
        body: getLocalizedText("CHANGE_PASSWORD_CONFIRMATION"),
        posButtonText: getLocalizedText("CHANGE_PASSWORD_BUTTON"),
        posButtonColor: "warning",
        negButtonText: getLocalizedText("CANCEL"),
        modalStyle: 'bg-blue',
        shouldCloseOnPosEvent: false
    },
}

var Http = axios.create({
    baseURL: Constants.AdminURL,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
    },
    timeout: 40000,
    /* transformRequest: [function (data) {
         return querystring.stringify(data);
     }],*/
    withCredentials: true
});

Http.interceptors.request.use(function (config) { // AXIOS REQUEST INTERCEPTOR

    var preferredLang = getPreferredLanguage();
    if (preferredLang)
        config.headers['Accept-Language'] = preferredLang.code;

    var selectedTenant = cacheGet('selectedTenant');
    if (selectedTenant)
        config.headers['X-TenantId'] = selectedTenant.tenantId;

    var url = config.url.toLowerCase();
    if (!url.endsWith('/login')
        && !url.endsWith('/forgotpassword')
        && !url.endsWith('/resetpassword')
        && !url.endsWith('/verifyuser')
        && !url.endsWith('/register')
        && !url.endsWith('/getforgotpasswordemail')) {
        var tokenStr = getToken();
        if (tokenStr != null)
            config.headers['Authorization'] = 'Bearer ' + tokenStr;
        else {
            userManager.signoutRedirect();
            return Promise.reject('TOKEN_NOT_FOUND')
        }
    }
    return config;
}, function (error) {
    console.error(error)
    return Promise.reject(error);
});

Http.interceptors.response.use(function (response) { // AXIOS RESPONSE INTERCEPTOR
    var data = response.data;
    if (_.get(data, 'data.isWorkflowStarted', false))
        return Promise.reject('WORKFLOW_DELEGATED');
    else if (_.get(data, 'data.isWorkflowFaulted', false))
        return Promise.reject('WORKFLOW_FAULTED');
    else if (_.get(data, 'data.isWorkflowFinished', false))
        return Promise.reject('WORKFLOW_FINISHED');
    else if ((data.status != undefined && data.status === false)
        || (data.success != undefined && data.success === false))
        return Promise.reject({
            reason: "UNSUCCESSFUL_RESPONSE",
            response
        });

    return response;
}, function (error) {
    console.error(error);

    if (error.response != null && error.response.status === 401) {
        console.warn('401 Unauthorized - Logging out...');
        setTimeout(() => userManager.signoutRedirect(), 2500);
    }

    return Promise.reject(error);
});

export { Http, ModalContent, Notifications, getResult, getTypeParameters };