import ContractBase, { TransactionPromise } from "./ContractBase";

// TODO Copied from ERC1155 update function parameters
export default class ERC721Contract extends ContractBase {

  public callBalanceOfAsync = (address: string, tokenId: number | string): Promise<number> => {
    return this.contract.methods.balanceOf(address, tokenId).call();
  }

  public callIsApprovedForAllAsync = (address: string, operator: string): Promise<boolean> => {
    return this.contract.methods.isApprovedForAll(address, operator).call();
  }

  public sendSetApprovalForAllAsync = (operator: string, approved: boolean): TransactionPromise => {
    var txPromise = this.contract.methods.setApprovalForAll(operator, approved).send();

    return ContractBase.defineTxIdMethod(txPromise);
  }

  public sendMintAsync = (to: string, tokenId: number | string, amount: number, r: number[], s: number[], v: number, data: string[] = []): TransactionPromise => {
    var txPromise = this.contract.methods.mint(to, tokenId, amount, r, s, v, data).send();

    return ContractBase.defineTxIdMethod(txPromise);
  }
}