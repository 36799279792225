import _ from 'lodash';
import React from 'react';
import { getLocalizedText } from './common/localizationManager';

//const Notifications = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
//const Scheduler = React.lazy(() => import('./views/Routes/Management/Scheduler/Scheduler'));
const AuthTemplates = React.lazy(() => import('./views/Routes/Management/AuthTemplates/AuthTemplates'));
const Dashboard = React.lazy(() => import('./views/Routes/Management/Dashboard/Dashboard'));
const InvestmentDashboard = React.lazy(() => import('./views/Routes/Management/Dashboard/InvestmentDashboard'));
const Tenant = React.lazy(() => import('./views/Routes/Management/Tenant/Tenant'));
//const Company_Mng = React.lazy(() => import('./views/Routes/Management/Companies/Company'));
const Users_Mng = React.lazy(() => import('./views/Routes/Management/Users/Users'));
const ResourceDefinition_Mng = React.lazy(() => import('./views/Routes/Management/ResourceDefinition/ResourceDefinition'));
const Parameters_Mng = React.lazy(() => import('./views/Routes/Management/Parameters/Parameters'));
const Kibana = React.lazy(() => import('./views/Routes/Management/Kibana/Kibana'));
//const Department = React.lazy(() => import('./views/Routes/Management/Department/Department'));
const CommunicationDefinitions_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationDefinitions/CommunicationDefinitions'));
const CommunicationTemplates_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationTemplates/CommunicationTemplates'));
const Notifications_Mng = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
//const LivePreview = React.lazy(() => import('./views/Routes/LivePreview/LivePreview'));
const TransactionLogs_Mng = React.lazy(() => import('./views/Routes/Management/TransactionLogs/TransactionLogs'));
const ChangePassword = React.lazy(() => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'));
const WorkflowDefinition = React.lazy(() => import('./views/Routes/Management/WorkflowDefinition/WorkflowDefinition'));
const WorkflowApproval = React.lazy(() => import('./views/Routes/Management/WorkflowApproval/WorkflowApproval'));

const eComBrand = React.lazy(() => import('./views/Routes/ECommerce/Brand/Brand'));
const eComCampaign = React.lazy(() => import('./views/Routes/ECommerce/Campaign/Campaign'));
const eComCategory = React.lazy(() => import('./views/Routes/ECommerce/Category/CategoryTree'));
const eComOrderItem = React.lazy(() => import('./views/Routes/ECommerce/OrderItem/OrderItem'));
const eComOrderPayment = React.lazy(() => import('./views/Routes/ECommerce/OrderPayment/OrderPayment'));
const eComOrder = React.lazy(() => import('./views/Routes/ECommerce/Order/Order'));
const eComStock = React.lazy(() => import('./views/Routes/ECommerce/Stock/Stock'));
const eComPrice = React.lazy(() => import('./views/Routes/ECommerce/Price/Price'));
const eComProduct = React.lazy(() => import('./views/Routes/ECommerce/Product/Product'));
const eComProductVariant = React.lazy(() => import('./views/Routes/ECommerce/ProductVariant/ProductVariant'));
const eComVariant = React.lazy(() => import('./views/Routes/ECommerce/Variant/Variant'));
const eComInstallment = React.lazy(() => import('./views/Routes/ECommerce/Installment/Installment'));
const eComPaymentChannel = React.lazy(() => import('./views/Routes/ECommerce/PaymentChannel/PaymentChannel'));
const eComProductUsageLog = React.lazy(() => import('./views/Routes/ECommerce/ProductUsageLog/ProductUsageLog'));
const eComUsedCampaign = React.lazy(() => import('./views/Routes/ECommerce/UsedCampaign/UsedCampaign'));
const eComCustomer = React.lazy(() => import('./views/Routes/ECommerce/Customer/Customer'));

const Account = React.lazy(() => import('./views/Routes/Account/Account'));
//const AuctionBid = React.lazy(() => import('./views/Routes/AuctionBid/AuctionBid'));
//const BundleItem = React.lazy(() => import('./views/Routes/BundleItem/BundleItem'));
const Category = React.lazy(() => import('./views/Routes/Category/Category'));
const Chain = React.lazy(() => import('./views/Routes/Chain/Chain'));
const Coin = React.lazy(() => import('./views/Routes/Coin/Coin'));
const Collection = React.lazy(() => import('./views/Routes/Collection/Collection'));
const Commission = React.lazy(() => import('./views/Routes/Commission/Commission'));
const CurrencyRate = React.lazy(() => import('./views/Routes/CurrencyRate/CurrencyRate'));
const Favorite = React.lazy(() => import('./views/Routes/Favorite/Favorite'));
const File = React.lazy(() => import('./views/Routes/File/File'));
//const Follower = React.lazy(() => import('./views/Routes/Follower/Follower'));
const Nft = React.lazy(() => import('./views/Routes/Nft/Nft'));
//const Nftcategory = React.lazy(() => import('./views/Routes/Nftcategory/Nftcategory'));
//const Nftowner = React.lazy(() => import('./views/Routes/Nftowner/Nftowner'));
const SaleAndAuction = React.lazy(() => import('./views/Routes/SaleAndAuction/SaleAndAuction'));
//const SocialMedia = React.lazy(() => import('./views/Routes/SocialMedia/SocialMedia'));
//const Trait = React.lazy(() => import('./views/Routes/Trait/Trait'));
const NativeTokenSale = React.lazy(() => import('./views/Routes/NativeTokenSale/NativeTokenSale'));
const NFTMint = React.lazy(() => import('./views/Routes/NFTMint/NFTMint'));
const CollectionVerify = React.lazy(() => import('./views/Routes/CollectionVerify/CollectionVerify'));
const ContractSync = React.lazy(() => import('./views/Routes/ContractSync/ContractSync'));
const UpcomingCollection = React.lazy(() => import('./views/Routes/UpcomingCollection/UpcomingCollection'));
const CollectionLaunchpad = React.lazy(() => import('./views/Routes/CollectionLaunchpad/CollectionLaunchpad'));
const LaunchpadWhitelist = React.lazy(() => import('./views/Routes/LaunchpadWhitelist/LaunchpadWhitelist'));
const CollectionApplication = React.lazy(() => import('./views/Routes/CollectionApplication/CollectionApplication'));
const Report = React.lazy(() => import('./views/Routes/Report/Report'));
const CollectionLaunchpadRound = React.lazy(() => import('./views/Routes/CollectionLaunchpadRound/CollectionLaunchpadRound'));
const LaunchpadWhitelistManage = React.lazy(() => import('./views/Routes/LaunchpadWhitelistManage/LaunchpadWhitelistManage'));
const Campaign = React.lazy(() => import('./views/Routes/Campaign/Campaign'));
const CampaignParticipant = React.lazy(() => import('./views/Routes/CampaignParticipant/CampaignParticipant'));
const CampaignTerm = React.lazy(() => import('./views/Routes/CampaignTerm/CampaignTerm'));
const RewardBalance = React.lazy(() => import('./views/Routes/RewardBalance/RewardBalance'));
const RewardClaimed = React.lazy(() => import('./views/Routes/RewardClaimed/RewardClaimed'));
const RewardEarned = React.lazy(() => import('./views/Routes/RewardEarned/RewardEarned'));
const RewardSetting = React.lazy(() => import('./views/Routes/RewardSetting/RewardSetting'));
const Block = React.lazy(() => import('./views/Routes/Block/Block'));
const Contract = React.lazy(() => import('./views/Routes/Contract/Contract'));
const Log = React.lazy(() => import('./views/Routes/Log/Log'));
const Token = React.lazy(() => import('./views/Routes/Token/Token'));
const TokenTransfer = React.lazy(() => import('./views/Routes/TokenTransfer/TokenTransfer'));
const Trace = React.lazy(() => import('./views/Routes/Trace/Trace'));
const Transaction = React.lazy(() => import('./views/Routes/Transaction/Transaction'));

var PageInfos = {
    Dashboard: {
        name: getLocalizedText("DASHBOARD"),
        url: `/dashboard`,
        component: Dashboard,
        resourceCode: 'MainPage',
        icon: 'icon-speedometer',
    },
    InvestmentDashboard: {
        name: getLocalizedText("INVESTMENT_DASHBOARD_LABEL"),
        url: `/investmentDashboard`,
        component: InvestmentDashboard,
        resourceCode: 'InvestmentDashboard_Res',
        icon: 'fa fa-area-chart',
    },
    /* Title_Admin: {
        title: true,
        name: getLocalizedText("TITLE_ADMIN"),
        resourceCode: 'AdminPages',
    }, */
    Menu_Admin: {
        name: getLocalizedText("ADMINISTRATION"),
        resourceCode: 'AdminPages',
        icon: 'cui-cog',
        defaultOpen: false
    },
    /* Company_Mng: {
        name: getLocalizedText("COMPANIES"),
        url: '/company',
        component: Company_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreCompany_Res',
        icon: 'icon-grid',
    },
    Department: {
        name: getLocalizedText("DEPARTMENTS_TITLE"),
        url: '/department',
        component: Department,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreDepartment_Res',
        icon: 'icon-puzzle',
    }, */
    AuthTemplate_Mng: {
        name: getLocalizedText("AUTH_TEMPLATES"),
        url: '/authTemplates',
        component: AuthTemplates,
        parentResourceCode: 'AdminPages',
        resourceCode: 'AuthTemplates',
        icon: 'icon-docs',
    },
    ResourceDefinition_Mng: {
        name: getLocalizedText("RESOURCE_DEFINITION"),
        url: '/resource-definitions',
        component: ResourceDefinition_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'ResourceDefinition',
        icon: 'icon-layers',
    },
    Parameters_Mng: {
        name: getLocalizedText("PARAMETERS"),
        url: '/parameters',
        component: Parameters_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'SystemParameters',
        icon: 'icon-list',
    },
    Menu_Communications: {
        name: getLocalizedText('COMMUNICATION_SETTINGS_LABEL'),
        resourceCode: 'Communication',
        parentResourceCode: 'AdminPages',
        icon: 'icon-feed',
    },
    CommunicationDefinitions_Mng: {
        name: getLocalizedText('COMMUNICATION_DEFINITIONS'),
        url: '/communicationDefinitions',
        component: CommunicationDefinitions_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationDefinitions_tab',
        icon: 'icon-note',
    },
    CommunicationTemplates_Mng: {
        name: getLocalizedText('COMMUNICATION_TEMPLATES'),
        url: '/communicationTemplates',
        component: CommunicationTemplates_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationTemplates_tab',
        icon: 'icon-envelope',
    },
    Menu_Workflow: {
        name: getLocalizedText('WF_TITLE'),
        resourceCode: 'TestResource',
        parentResourceCode: 'AdminPages',
        icon: 'fa fa-briefcase',
        badge: { variant: 'info', text: 'NEW' }
    },
    WorkflowApproval: {
        name: getLocalizedText("WF_DASHBOARD"),
        url: '/workflowApproval',
        path: '/workflowApproval/:definitionId?',
        component: WorkflowApproval,
        resourceCode: 'TestResource',
        parentResourceCode: 'TestResource',
        icon: 'icon-speedometer',
    },
    WorkflowDefinition: {
        name: getLocalizedText("WF_DEFINITIONS"),
        url: '/workflowDefinition',
        component: WorkflowDefinition,
        resourceCode: 'TestResource',
        parentResourceCode: 'TestResource',
        icon: 'icon-note',
    },
    SearchMonitor_Mng: {
        name: getLocalizedText('SEARCH_MONITOR_LABEL'),
        url: '/kibana',
        component: Kibana,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreSearchMonitor_Res',
        icon: 'icon-magnifier',
    },
    AuditLogs_Mng: {
        name: getLocalizedText('AUDIT_LOGS'),
        url: '/transactionLogs',
        component: TransactionLogs_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'TransactionLogs_Res',
        icon: 'icon-book-open',
    },
    Notifications: {
        name: getLocalizedText("NOTIFICATIONS"),
        url: '/notifications/:id?',
        component: Notifications_Mng,
        resourceCode: 'Notifications',
        parentResourceCode: 'AdminPages',
        icon: 'icon-badge',
        showInSidebar: false,
    },
    /* LivePreview: {
        name: getLocalizedText("LIVE_PREVIEW_LABEL"),
        url: '/livePreview',
        component: LivePreview,
        resourceCode: 'TestResource',
        icon: 'icon-eye',
        badge: { variant: 'info', text: 'NEW' }
    }, */
    ChangePassword: {
        name: getLocalizedText('CHANGE_PASSWORD_LABEL'),
        url: '/changePassword',
        component: ChangePassword,
        resourceCode: 'ChangePassword_Res',
        showInSidebar: false,
    },
    Menu_ECommerce: {
        name: getLocalizedText("ECOM_TITLE"),
        resourceCode: 'ECommercePages',
        icon: 'icon-plus',
        defaultOpen: true
    },
    ECommerce_Brand: {
        name: getLocalizedText('ECOM_BRAND'),
        url: '/brand',
        component: eComBrand,
        resourceCode: 'Brand',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-star',
    },
    ECommerce_Campaign: {
        name: getLocalizedText('ECOM_CAMPAIGN'),
        url: '/ecom-campaign',
        component: eComCampaign,
        resourceCode: 'Campaign',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-gift',
    },
    ECommerce_Customer: {
        name: getLocalizedText('ECOM_CUSTOMER'),
        url: '/customer',
        component: eComCustomer,
        resourceCode: 'Customer',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-user',
    },
    ECommerce_Category: {
        name: getLocalizedText('ECOM_CATEGORY'),
        url: '/ecom-category',
        component: eComCategory,
        resourceCode: 'Category',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-list',
    },
    ECommerce_Product: {
        name: getLocalizedText('ECOM_PRODUCT'),
        url: '/product',
        component: eComProduct,
        resourceCode: 'Product',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-dropbox',
    },
    ECommerce_Variant: {
        name: getLocalizedText('ECOM_VARIANT'),
        url: '/variant',
        component: eComVariant,
        resourceCode: 'Variant',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-tag',
    },
    ECommerce_ProductVariant: {
        name: getLocalizedText('ECOM_PRODUCT_VARIANT'),
        url: '/productVariant',
        component: eComProductVariant,
        resourceCode: 'ProductVariant',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-shuffle',
    },
    ECommerce_Stock: {
        name: getLocalizedText('ECOM_STOCK'),
        url: '/stock',
        component: eComStock,
        resourceCode: 'Stock',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-square',
    },
    ECommerce_Price: {
        name: getLocalizedText('ECOM_PRICE'),
        url: '/price',
        component: eComPrice,
        resourceCode: 'Price',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-money',
    },
    ECommerce_Order: {
        name: getLocalizedText('ECOM_ORDER'),
        url: '/order',
        component: eComOrder,
        resourceCode: 'Order',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-shopping-cart',
    },
    /*     ECommerce_OrderItem: {
            name: getLocalizedText('ECOM_ORDER_ITEM'),
            url: '/orderItem',
            component: OrderItem,
            resourceCode: 'OrderItem',
            parentResourceCode: 'ECommercePages',
            icon: 'fa fa-cart-plus',
        },
        ECommerce_OrderPayment: {
            name: getLocalizedText('ECOM_ORDER_PAYMENT'),
            url: '/orderPayment',
            component: OrderPayment,
            resourceCode: 'OrderPayment',
            parentResourceCode: 'ECommercePages',
            icon: 'fa fa-credit-card',
        }, */
    ECommerce_Installment: {
        name: getLocalizedText('ECOM_INSTALLMENT'),
        url: '/installment',
        component: eComInstallment,
        resourceCode: 'Installment',
        parentResourceCode: 'ECommercePages',
        icon: 'fa fa-calendar-plus-o',
    },
    ECommerce_PaymentChannel: {
        name: getLocalizedText('ECOM_PAYMENT_CHANNEL'),
        url: '/paymentChannel',
        component: eComPaymentChannel,
        resourceCode: 'PaymentChannel',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-layers',
    },
    ECommerce_ProductUsageLog: {
        name: getLocalizedText('ECOM_PRODUCT_USAGE_LOG'),
        url: '/productUsageLog',
        component: eComProductUsageLog,
        resourceCode: 'ProductUsageLog',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-chart',
    },
    ECommerce_UsedCampaign: {
        name: getLocalizedText('ECOM_USED_CAMPAIGN'),
        url: '/usedCampaign',
        component: eComUsedCampaign,
        resourceCode: 'ProductUsageLog',
        parentResourceCode: 'ECommercePages',
        icon: 'icon-present',
    },
    Menu_User_Management: {
        name: getLocalizedText("User Management"),
        resourceCode: 'MenuUserManagement',
        icon: 'fa fa-address-card',
        defaultOpen: false
    },
    Tenant: {
        name: getLocalizedText('TENANTS'),
        url: '/tenant',
        component: Tenant,
        resourceCode: 'Tenant_Res',
        parentResourceCode: 'MenuUserManagement',
        icon: 'icon-book-open'
    },
    Users_Mng: {
        name: getLocalizedText("USERS"),
        url: '/users',
        component: Users_Mng,
        resourceCode: 'Users',
        parentResourceCode: 'MenuUserManagement',
        icon: 'icon-people',
    },
    Account: {
        name: getLocalizedText('Wallet/Account'),
        url: '/account',
        component: Account,
        resourceCode: 'Account_Res',
        parentResourceCode: 'MenuUserManagement',
        icon: 'fa fa-bank',
    },
    /* AuctionBid: {
        name: getLocalizedText('Auction Bid'),
        url: '/auctionBid',
        component: AuctionBid,
        resourceCode: 'AuctionBid_Res',
        icon: 'icon-energy',
    }, */

    Menu_Definitions: {
        name: getLocalizedText("Definitions"),
        resourceCode: 'MenuDefinitions',
        icon: 'fa fa-book',
        defaultOpen: false
    },
    Category: {
        name: getLocalizedText('Category'),
        url: '/nftcategorydef',
        component: Category,
        resourceCode: 'Category_Res',
        parentResourceCode: 'MenuDefinitions',
        icon: 'icon-energy',
    },
    Chain: {
        name: getLocalizedText('Chain'),
        url: '/chain',
        component: Chain,
        resourceCode: 'Chain_Res',
        parentResourceCode: 'MenuDefinitions',
        icon: 'fa fa-chain',
    },
    Coin: {
        name: getLocalizedText('Coin'),
        url: '/coin',
        component: Coin,
        resourceCode: 'Coin_Res',
        parentResourceCode: 'MenuDefinitions',
        icon: 'fa fa-money',
    },
    Commission: {
        name: getLocalizedText('Commission'),
        url: '/commission',
        component: Commission,
        resourceCode: 'Commission_Res',
        parentResourceCode: 'MenuDefinitions',
        icon: 'fa fa-pie-chart',
    },
    CurrencyRate: {
        name: getLocalizedText('Currency Rate'),
        url: '/currencyRate',
        component: CurrencyRate,
        resourceCode: 'CurrencyRate_Res',
        parentResourceCode: 'MenuDefinitions',
        icon: 'icon-energy',
    },

    Menu_NFT: {
        name: getLocalizedText("NFTs"),
        resourceCode: 'MenuNFT',
        icon: 'fa fa-connectdevelop',
        defaultOpen: false
    },
    Collection: {
        name: getLocalizedText('Collection'),
        url: '/collection',
        component: Collection,
        resourceCode: 'Collection_Res',
        parentResourceCode: 'MenuNFT',
        icon: 'fa fa-table',
    },
    Nft: {
        name: getLocalizedText('NFT'),
        url: '/nft',
        component: Nft,
        resourceCode: 'NFT_Res',
        parentResourceCode: 'MenuNFT',
        icon: 'fa fa-btc',
    },
    /*     BundleItem: {
            name: getLocalizedText('Bundle Item'),
            url: '/bundleItem',
            component: BundleItem,
            resourceCode: 'BundleItem_Res',
            parentResourceCode: 'MenuNFT',
            icon: 'fa fa-pie-chart',
        }, */
    Favorite: {
        name: getLocalizedText('Favorite'),
        url: '/favorite',
        component: Favorite,
        resourceCode: 'Favorite_Res',
        parentResourceCode: 'MenuNFT',
        icon: 'fa fa-heart',
    },
    SaleAndAuction: {
        name: getLocalizedText('Sale And Auction'),
        url: '/saleAndAuction',
        component: SaleAndAuction,
        resourceCode: 'SaleAndAuction_Res',
        parentResourceCode: 'MenuNFT',
        icon: 'fa fa-cart-plus',
    },
    Report: {
        name: getLocalizedText("REPORT"),
        url: '/report',
        component: Report,
        resourceCode: 'Report_Res',
        parentResourceCode: 'MenuNFT',
        icon: 'fa fa-bug',
    },
    /*File: {
        name: getLocalizedText('File'),
        url: '/file',
        component: File,
        resourceCode: 'File_Res',
        icon: 'icon-energy',
    },
     Follower: {
        name: getLocalizedText('Follower'),
        url: '/follower',
        component: Follower,
        resourceCode: 'Follower_Res',
        icon: 'icon-energy',
    },
    Nftcategory: {
        name: getLocalizedText('Nftcategory'),
        url: '/nftcategory',
        component: Nftcategory,
        resourceCode: 'NFTCategory_Res',
        icon: 'icon-energy',
    },
    Nftowner: {
        name: getLocalizedText('Nftowner'),
        url: '/nftowner',
        component: Nftowner,
        resourceCode: 'NFTOwner_Res',
        icon: 'icon-energy',
    },
    SocialMedia: {
        name: getLocalizedText('Social Media'),
        url: '/socialMedia',
        component: SocialMedia,
        resourceCode: 'SocialMedia_Res',
        icon: 'icon-energy',
    },
    Trait: {
        name: getLocalizedText('Trait'),
        url: '/trait',
        component: Trait,
        resourceCode: 'Trait_Res',
        icon: 'icon-energy',
    }, */
    NativeTokenSale: {
        name: getLocalizedText('NATIVE_TOKEN_SALE_MENU_LABEL'),
        url: '/NativeTokenSale',
        component: NativeTokenSale,
        resourceCode: 'NativeTokenSale_Res',
        icon: 'fa fa-check',
    },
    NFTMint: {
        name: getLocalizedText("NFT_MINT"),
        url: '/nftmint',
        component: NFTMint,
        resourceCode: 'TestResource',
        icon: 'icon-eye',
        badge: { variant: 'info', text: 'NEW' }
    },

    Menu_SearchEngine: {
        name: getLocalizedText("SEARCH_ENGINE_MNG_MENU_LABEL"),
        resourceCode: 'SearchEngineMenu_Res',
        icon: 'cui-cog',
        defaultOpen: false
    },
    CollectionVerify: {
        name: getLocalizedText("COLLECTION_VERIFY_MENU_LABEL"),
        url: '/CollectionVerify',
        component: CollectionVerify,
        resourceCode: 'SE_CollectionVerify_Res',
        parentResourceCode: 'SearchEngineMenu_Res',
        icon: 'fa fa-check-circle',
    },
    ContractSync: {
        name: getLocalizedText("CONTRACT_SYNC_MENU_LABEL"),
        url: '/ContractSync',
        component: ContractSync,
        resourceCode: 'SE_ContractSync_Res',
        parentResourceCode: 'SearchEngineMenu_Res',
        icon: 'fa fa-refresh',
    },
    UpcomingCollection: {
        name: getLocalizedText("UPCOMING_COLLECTION_MENU_LABEL"),
        url: '/UpcomingCollection',
        component: UpcomingCollection,
        resourceCode: 'UpcomingCollection_Res',
        parentResourceCode: 'SearchEngineMenu_Res',
        icon: 'icon-energy',
    },
    Menu_Launchpad: {
        name: getLocalizedText('LAUNCHPAD_MENU_LABEL'),
        resourceCode: 'LaunchpadPages',
        icon: 'fa fa-connectdevelop',
        defaultOpen: false
    },
    CollectionApplication: {
        name: getLocalizedText('LAUNCHPAD_APPLICATION_MENU_LABEL'),
        url: '/collectionApplication',
        component: CollectionApplication,
        resourceCode: 'CollectionApplication_Res',
        icon: 'icon-energy',
        parentResourceCode: 'LaunchpadPages',
    },
    CollectionLaunchpad: {
        name: getLocalizedText('LAUNCHPAD_LAUNCH_MENU_LABEL'),
        url: '/collectionLaunchpad',
        component: CollectionLaunchpad,
        resourceCode: 'CollectionLaunchpad_Res',
        icon: 'icon-energy',
        parentResourceCode: 'LaunchpadPages',
    },
    LaunchpadWhitelist: {
        name: getLocalizedText('LAUNCHPAD_WHITELIST_MENU_LABEL'),
        url: '/launchpadWhitelist',
        component: LaunchpadWhitelist,
        resourceCode: 'LaunchpadWhitelist_Res',
        icon: 'icon-energy',
        parentResourceCode: 'LaunchpadPages',
    },
    LaunchpadWhitelistManage: {
        name: getLocalizedText('Launchpad Whitelist Manage'),
        url: '/launchpadWhitelistManage',
        component: LaunchpadWhitelistManage,
        resourceCode: 'LaunchpadWhitelist_Res',
        icon: 'icon-energy',
        parentResourceCode: 'LaunchpadPages',
    },
    CollectionLaunchpadRound: {
        name: getLocalizedText('LAUNCHPAD_ROUND_MENU_LABEL'),
        url: '/collectionLaunchpadRound',
        component: CollectionLaunchpadRound,
        resourceCode: 'CollectionLaunchpadRound_Res',
        parentResourceCode: 'LaunchpadPages',
        icon: 'icon-energy',
    },
    Menu_Campaign: {
        name: getLocalizedText('CAMPAIGN_MNG_MENU_LABEL'),
        resourceCode: 'Campaign_Menu_Res',
        icon: 'cui-cog',
        defaultOpen: false
    },
    Campaign: {
        name: getLocalizedText('CAMPAIGN_MENU_LABEL'),
        url: '/campaign',
        component: Campaign,
        resourceCode: 'Campaign_Res',
        parentResourceCode: 'Campaign_Menu_Res',
        icon: 'icon-energy',
    },
    CampaignParticipant: {
        name: getLocalizedText('CAMPAIGN_PARTICIPANTS_MENU_LABEL'),
        url: '/campaignParticipant',
        component: CampaignParticipant,
        resourceCode: 'CampaignParticipant_Res',
        parentResourceCode: 'Campaign_Menu_Res',
        icon: 'icon-energy',
    },
    CampaignTerm: {
        name: getLocalizedText('CAMPAIGN_TERMS_MENU_LABEL'),
        url: '/campaignTerm',
        component: CampaignTerm,
        resourceCode: 'CampaignTerm_Res',
        parentResourceCode: 'Campaign_Menu_Res',
        icon: 'icon-energy',
        showInSidebar: false,
    },
    Menu_Reward: {
        name: getLocalizedText('REWARD_MNG_MENU_LABEL'),
        resourceCode: 'Reward_Menu_Res',
        icon: 'cui-cog',
        defaultOpen: false
    },
    RewardSetting: {
        name: getLocalizedText('Reward Settings'),
        url: '/rewardSetting',
        component: RewardSetting,
        resourceCode: 'RewardSetting_Res',
        parentResourceCode: 'Reward_Menu_Res',
        icon: 'icon-energy',
    },
    RewardEarned: {
        name: getLocalizedText('Reward Earned'),
        url: '/rewardEarned',
        component: RewardEarned,
        resourceCode: 'RewardEarned_Res',
        parentResourceCode: 'Reward_Menu_Res',
        icon: 'icon-energy',
    },
    RewardClaimed: {
        name: getLocalizedText('Reward Claimed'),
        url: '/rewardClaimed',
        component: RewardClaimed,
        resourceCode: 'RewardClaimed_Res',
        parentResourceCode: 'Reward_Menu_Res',
        icon: 'icon-energy',
    },
    RewardBalance: {
        name: getLocalizedText('Reward Balance'),
        url: '/rewardBalance',
        component: RewardBalance,
        resourceCode: 'RewardBalance_Res',
        parentResourceCode: 'Reward_Menu_Res',
        icon: 'icon-energy',
    },

    Block: {
        name: getLocalizedText('Block'),
        url: '/block',
        component: Block,
        resourceCode: 'Block_Res',
        icon: 'icon-energy',
    },
    Contract: {
        name: getLocalizedText('Contract'),
        url: '/contract',
        component: Contract,
        resourceCode: 'Contract_Res',
        icon: 'icon-energy',
    },
    Log: {
        name: getLocalizedText('Log'),
        url: '/log',
        component: Log,
        resourceCode: 'Log_Res',
        icon: 'icon-energy',
    },
    Token: {
        name: getLocalizedText('Token'),
        url: '/token',
        component: Token,
        resourceCode: 'Token_Res',
        icon: 'icon-energy',
    },
    TokenTransfer: {
        name: getLocalizedText('Token Transfer'),
        url: '/tokenTransfer',
        component: TokenTransfer,
        resourceCode: 'TokenTransfer_Res',
        icon: 'icon-energy',
    },
    Trace: {
        name: getLocalizedText('Trace'),
        url: '/trace',
        component: Trace,
        resourceCode: 'Trace_Res',
        icon: 'icon-energy',
    },
    Transaction: {
        name: getLocalizedText('Transaction'),
        url: '/transaction',
        component: Transaction,
        resourceCode: 'Transaction_Res',
        icon: 'icon-energy',
    }
};

// Scheduler: {
//     name: "Scheduler",
//     url: '/scheduler',
//     component: Scheduler,
//     resourceCode: '',
//     icon: 'icon-clock',
// },

export var Pages = function () {
    for (var page in PageInfos) {
        PageInfos[page].key = page;

        if (PageInfos[page].url && PageInfos[page].path == null)
            PageInfos[page].path = `${PageInfos[page].url}`;
    }
    // let sortedPagesByName = Object.values(PageInfos).sort((a, b) =>
    // (a.name === 'Dashboard' || b.name === 'Dashboard') || a.name.localeCompare(b.name));
    return PageInfos;
}();

export var PagesAsNav = function () {
    var pages = _.values(_.cloneDeep(Pages)).filter(page => page.showInSidebar !== false);
    var toBeDeleted = [];
    for (var page of pages) {
        if (page.defaultOpen) {
            delete page.defaultOpen
            if (!page.url)
                page.url = '';
        }
        if (page.parentResourceCode) {

            var foundPage = pages.find(x => page.parentResourceCode && x.resourceCode == page.parentResourceCode);
            if (foundPage) {
                if (foundPage.children == null)
                    foundPage.children = [];

                foundPage.children.push(page);
                toBeDeleted.push(pages.findIndex(x => x.key == page.key));
            }
        }
    }

    toBeDeleted.sort((a, b) => a - b);
    toBeDeleted.reverse();

    for (var ind of toBeDeleted) {
        pages.splice(ind, 1);
    }
    return pages;
}();

export var PagesAsRoute = function () {
    var pages = _.values(_.cloneDeep(Pages));

    for (var page of pages) {
        if (page.url && page.parentResourceCode == null)
            page.exact = true;
    }
    return pages;
}();