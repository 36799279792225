import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import LoadingOverlay from './LoadingOverlay';

export default class GenericModalCls extends React.Component {

    render() {
        var { isOpen, toggle, modalContent, isLoading, ...restProps } = this.props;
        return (
            <>
                {modalContent != null ?
                    <Modal {...restProps} isOpen={isOpen} toggle={toggle}>
                        <ModalHeader
                            className={modalContent.modalStyle || "bg-blue"}
                            style={{ color: 'white' }}>
                            <span>{modalContent.title}</span>
                        </ModalHeader>
                        <ModalBody style={{ wordBreak: 'break-word' }}>
                            {modalContent.body}
                            {isLoading != null ?
                                <LoadingOverlay isLoading={isLoading} />
                                : null}
                        </ModalBody>
                        <ModalFooter>
                            {modalContent.negButtonText
                                ? <Button
                                    color={modalContent.negButtonColor}
                                    onClick={modalContent.negButtonEvent ? modalContent.negButtonEvent : this.props.toggle}>
                                    {modalContent.negButtonText}
                                </Button>
                                : null
                            }
                            <Button
                                color={modalContent.posButtonColor}
                                disabled={modalContent.posButtonDisabled ? modalContent.posButtonDisabled() : false}
                                onClick={() => {
                                    if (modalContent.posButtonEvent)
                                        modalContent.posButtonEvent();
                                    if (modalContent.shouldCloseOnPosEvent == null || !!modalContent.shouldCloseOnPosEvent)
                                        this.props.toggle();
                                }}>
                                {modalContent.posButtonText}
                            </Button>
                        </ModalFooter>
                    </Modal>
                    : null}
            </>
        )
    }
}